<template>
  <v-card>
    <v-card-title>Set Theme to Users</v-card-title>
    <v-card-text>
      <users-selector-autocomplete v-model="users"/>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn small color="error" @click="cancel">CANCEL</v-btn>
      <v-btn small color="success" @click="setTheme">OK</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import usersSelectorAutocomplete from '../selectors/usersSelectorAutocomplete.vue';

export default {
  data() {
    return {
      users: [],
    };
  },
  components: {
    usersSelectorAutocomplete,
  },
  methods: {
    reset() {
      this.users = [];
    },
    setTheme() {
      this.$emit('setTheme', [...this.users]);
      this.reset();
    },
    cancel() {
      this.$emit('close');
      this.reset();
    },
  },
};
</script>
