<template>
  <v-card flat>
    <v-container fluid>
      <v-row
        v-if="config.configuration_name || config.start_date || config.end_date"
      >
        <v-col cols="12" md="4" v-if="config.configuration_name">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                config.configuration_name
              }}</v-list-item-title>
              <v-list-item-subtitle>Configuration Name</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="4" v-if="config.start_date">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                config.start_date | moment("lll")
              }}</v-list-item-title>
              <v-list-item-subtitle>Start date</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="4" v-if="config.end_date">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                config.end_date | moment("lll")
              }}</v-list-item-title>
              <v-list-item-subtitle>End date</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-divider
        v-if="config.configuration_name || config.start_date || config.end_date"
      />
      <v-row>
        <v-col cols="12" md="4">
          <v-subheader>Name</v-subheader>
          <v-list-item v-for="name in config.name" :key="name.text">
            <v-list-item-content>
              <v-list-item-title>{{ name.text }}</v-list-item-title>
              <v-list-item-subtitle>{{
                name.languages.join(", ")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="4">
          <v-subheader>Unit</v-subheader>
          <v-list-item v-for="unit in config.unit" :key="unit.text">
            <v-list-item-content>
              <v-list-item-title>{{ unit.text }}</v-list-item-title>
              <v-list-item-subtitle>{{
                unit.languages.join(", ")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="4">
          <v-subheader>Access Levels</v-subheader>
          <v-list-group
            no-action
            v-for="access_levels in config.access_levels"
            :key="access_levels.level"
          >
            <template v-slot:activator>
              <v-list-item-title
                >Level {{ access_levels.level }}</v-list-item-title
              >
            </template>
            <v-list-item dense>
              <v-list-item-content>
                Request: {{ access_levels.request }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-content>
                Write: {{ access_levels.write }}
              </v-list-item-content>
            </v-list-item>

            <v-list-group no-action sub-group>
              <template v-slot:activator>
                <v-list-item-title>Read</v-list-item-title>
              </template>
              <v-list-item dense>
                <v-list-item-content>
                  Array: {{ access_levels.read.array }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-content>
                  Temporal plot: {{ access_levels.read.temporal_plot }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense v-if="access_levels.read.graphical_sum !== undefined">
                <v-list-item-content>
                  Graphical Sum: {{ access_levels.read.graphical_sum }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense v-if="access_levels.read.graphical_mean !== undefined">
                <v-list-item-content>
                  Graphical Mean: {{ access_levels.read.graphical_mean }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item dense v-if="access_levels.read.map_sum !== undefined">
                <v-list-item-content>
                  Map Sum: {{ access_levels.read.map_sum }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense v-if="access_levels.read.map_mean !== undefined">
                <v-list-item-content>
                  Map Mean: {{ access_levels.read.map_mean }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-content>
                  Activity Count: {{ access_levels.read.activity_count }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-content>
                  Activity last-first:
                  {{ access_levels.read["activity_last-first"] }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-content>
                  Activity max-min:
                  {{ access_levels.read["activity_max-min"] }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-content>
                  Activity max:
                  {{ access_levels.read.activity_max }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-content>
                  Activity mean:
                  {{ access_levels.read.activity_mean }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-content>
                  Activity sum:
                  {{ access_levels.read.activity_sum }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-content>
                  Activity first:
                  {{ access_levels.read.activiry_first }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-content>
                  Activity last:
                  {{ access_levels.read.activity_last }}
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-group>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" md="4" v-if="config.can_read">
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-title>CAN Read</v-list-item-title>
            </template>
            <v-list-item dense v-for="field in canReadField" :key="field.key">
              <v-list-item-content
                >{{ field.text }}:
                {{ config.can_read[field.key] }}</v-list-item-content
              >
            </v-list-item>
          </v-list-group>
        </v-col>
        <v-col cols="12" md="4" v-if="config.can_request">
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-title>CAN Request</v-list-item-title>
            </template>
            <v-list-item
              dense
              v-for="field in canRequestField"
              :key="field.key"
            >
              <v-list-item-content
                >{{ field.text }}:
                {{ config.can_request[field.key] }}</v-list-item-content
              >
            </v-list-item>
          </v-list-group>
        </v-col>
        <v-col cols="12" md="4" v-if="config.can_write">
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-title>CAN Write</v-list-item-title>
            </template>
            <v-list-item dense v-for="field in canWriteField" :key="field.key">
              <v-list-item-content
                >{{ field.text }}:
                {{ config.can_write[field.key] }}</v-list-item-content
              >
            </v-list-item>
          </v-list-group>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: ['config'],
  components: {},
  data() {
    return {
      canReadField: [
        { text: 'CAN id', key: 'can_id' },
        { text: 'Extended id', key: 'extended_id' },
        { text: 'Id mask', key: 'id_mask' },
        { text: 'Start bit', key: 'start_bit' },
        { text: 'Length', key: 'len' },
        { text: 'Byte order', key: 'byte_order' },
        { text: 'Offset', key: 'offset' },
        { text: 'Factor', key: 'factor' },
        { text: 'Min_value', key: 'min_value' },
        { text: 'Max_value', key: 'max_value' },
        { text: 'Type', key: 'type' },
      ],
      canRequestField: [
        { text: 'Id', key: 'id' },
        { text: 'extended', key: 'extended' },
        { text: 'Start bit', key: 'start_bit' },
        { text: 'Length', key: 'len' },
        { text: 'Byte order', key: 'byte_order' },
        { text: 'Offset', key: 'offset' },
        { text: 'Factor', key: 'factor' },
        { text: 'Min_value', key: 'min_value' },
        { text: 'Max_value', key: 'max_value' },
        { text: 'Type', key: 'type' },
        { text: 'Default Frame', key: 'default_frame' },
      ],
      canWriteField: [
        { text: 'Id', key: 'id' },
        { text: 'extended', key: 'extended' },
        { text: 'Start bit', key: 'start_bit' },
        { text: 'Length', key: 'len' },
        { text: 'Byte order', key: 'byte_order' },
        { text: 'Offset', key: 'offset' },
        { text: 'Factor', key: 'factor' },
        { text: 'Min_value', key: 'min_value' },
        { text: 'Max_value', key: 'max_value' },
        { text: 'Type', key: 'type' },
        { text: 'Default Frame', key: 'default_frame' },
      ],
    };
  },
};
</script>
