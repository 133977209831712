<template>
  <v-card elevation="4">
    <v-card-title> Canbus Config </v-card-title>
    <v-card-text>
      <v-select
        :items="canbusconfigs"
        label="Name"
        item-text="name"
        return-object
        v-model="select"
      ></v-select>
    </v-card-text>
    <counter-canbus-configs-details-can v-if="select" :cans="select.cans" />
    <v-card-text v-if="select">
      {{ select.createdAt | moment("lll") }} - {{ select.creator.firstname }}
      {{ select.creator.lastname }}
    </v-card-text>
    <v-card-actions v-if="select" class="justify-center">
      <v-btn small color="secondary" @click="dialogSetCounters = true"
        >SET TO COUNTERS</v-btn
      >
    </v-card-actions>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer />
      <add-canbus-config-dialog @update="update" />
    </v-card-actions>
    <v-dialog v-model="dialogSetCounters" persistent width="600">
      <set-canbus-config-to-counters
        @close="dialogSetCounters = false"
        @setCanbusConfig="setCanbusConfig"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import AddCanbusConfigDialog from './AddCanbusConfigDialog.vue';
import counterCanbusConfigsDetailsCan from '../counters/counterCanbusConfigsDetailsCan.vue';
import SetCanbusConfigToCounters from './SetCanbusConfigToCounters.vue';

export default {
  props: ['canbusconfigs'],
  components: {
    AddCanbusConfigDialog,
    counterCanbusConfigsDetailsCan,
    SetCanbusConfigToCounters,
  },
  data() {
    return {
      select: '',
      dialogSetCounters: false,
    };
  },
  methods: {
    ...mapActions(['setCanbusCounterConfigs']),
    update(val) {
      this.select = val;
    },
    setCanbusConfig(val) {
      this.dialogSetCounters = false;
      this.setCanbusCounterConfigs({
        idCanbusConfig: this.select.id,
        counters: val,
      });
    },
  },
};
</script>
