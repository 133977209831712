<template>
  <v-container class="params">
    <v-row>
      <v-col>
        <admin-account-component :accounts="adminAccount" />
      </v-col>
      <v-col cols="12">
        <themes-component :themes="themes" />
      </v-col>
      <v-col cols="12">
        <canbus-config-component :canbusconfigs="canbusconfigs" />
      </v-col>
      <v-col cols="12">
        <canbus-values-config-component :canbusvaluesconfigs="canbusvaluesconfigs" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import adminAccountComponent from '@/components/params/AdminAccount.vue';
import themesComponent from '@/components/params/Themes.vue';
import canbusConfigComponent from '@/components/params/CanbusConfig.vue';
import canbusValuesConfigComponent from '@/components/params/CanbusValuesConfig.vue';

export default {
  components: {
    themesComponent,
    canbusConfigComponent,
    canbusValuesConfigComponent,
    adminAccountComponent,
  },
  mounted() {
    this.getThemes();
    this.getCanbusConfig();
    this.getCanbusValuesConfig();
    this.getAdminAccounts();
  },
  computed: {
    ...mapGetters(['themes', 'canbusconfigs', 'canbusvaluesconfigs', 'adminAccount']),
  },
  methods: {
    ...mapActions(['getThemes', 'getCanbusConfig', 'getCanbusValuesConfig', 'getAdminAccounts']),
  },
};
</script>

<style scoped>
</style>
