<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small color="success" dark v-bind="attrs" v-on="on"> ADD </v-btn>
    </template>
    <add-canbus-config @close='dialog = false' @update='update' />
  </v-dialog>
</template>

<script>
import AddCanbusConfig from './AddCanbusConfig.vue';

export default {
  data() {
    return {
      dialog: false,
    };
  },
  components: {
    AddCanbusConfig,
  },
  methods: {
    update(val) {
      this.dialog = false;
      this.$emit('update', val);
    },
  },
};
</script>
