<template>
  <v-card>
    <v-card-title> ADD USERS </v-card-title>
    <v-card-text>
      <v-text-field label="Emails" v-model="emails"></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn small color="error" @click="cancel">CANCEL</v-btn>
      <v-btn small color="success" @click="addEmails">OK</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['idAccount'],
  data() {
    return {
      emails: '',
    };
  },
  methods: {
    ...mapActions(['addEmailInAdminAccounts', 'getAdminAccounts']),
    cancel() {
      this.emails = '';
      this.$emit('close');
    },
    async addEmails() {
      await this.addEmailInAdminAccounts(
        {
          id: this.idAccount,
          emails: [this.emails],
        },
      );
      this.getAdminAccounts();
      this.cancel();
    },
  },
};
</script>
