<template>
  <v-card>
    <v-card-title> Add Data </v-card-title>

    <v-card-text>
      <v-subheader>Name</v-subheader>
      <v-list-item
        dense
        v-for="(name, indexName) in data.name"
        :key="indexName">
        {{ name.text }} ({{ name.languages.join(', ') }})
        <v-icon
          small
          color="error"
          @click="removeName(indexName)"
          >mdi-delete</v-icon
        >
      </v-list-item>
      <v-container>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="tpsName.name"
              label="Text"
              dense
              solo></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpsName.languages"
              label="Languages"
              dense
              solo></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              @click="addDatasName()"
              icon
              color="success"
              ><v-icon> mdi-plus</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-subheader>Unit</v-subheader>
      <v-list-item
        dense
        v-for="(unit, indexUnit) in data.unit"
        :key="indexUnit">
        {{ unit.text }} ({{ unit.languages.join(', ') }})
        <v-icon
          small
          color="error"
          @click="removeUnit(indexUnit)"
          >mdi-delete</v-icon
        >
      </v-list-item>
      <v-container>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="tpsUnit.name"
              label="Text"
              dense
              solo></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpsUnit.languages"
              label="Languages"
              dense
              solo></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              @click="addDatasUnit()"
              icon
              color="success"
              ><v-icon> mdi-plus</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-subheader>Access Level</v-subheader>
      <v-container>
        <v-row>
          <v-col
            cols="4"
            v-for="(access_levels, indexAL) in data.access_levels"
            :key="indexAL">
            <v-card>
              <v-card-text>
                <v-text-field
                  type="number"
                  v-model="access_levels.level"
                  label="Level"
                  dense
                  solo></v-text-field>
                <v-checkbox
                  v-model="access_levels.write"
                  label="write"
                  hide-details></v-checkbox>
                <v-checkbox
                  v-model="access_levels.request"
                  label="request"
                  hide-details></v-checkbox>
                <v-checkbox
                  v-model="access_levels.read.array"
                  label="read.array"
                  hide-details></v-checkbox>
                <v-checkbox
                  v-model="access_levels.read.temporal_plot"
                  label="read.temporal_plot"
                  hide-details></v-checkbox>
                <v-checkbox
                  v-model="access_levels.read.map_sum"
                  label="read.map_sum"
                  hide-details></v-checkbox>
                <v-checkbox
                  v-model="access_levels.read.map_mean"
                  label="read.map_mean"
                  hide-details></v-checkbox>
                <v-checkbox
                  v-model="access_levels.read.activity_count"
                  label="read.activity_count"
                  hide-details></v-checkbox>
                <v-checkbox
                  v-model="access_levels.read['activity_last-first']"
                  label="read.activity_last-first"
                  hide-details></v-checkbox>
                <v-checkbox
                  v-model="access_levels.read['activity_max-min']"
                  label="read.activity_max-min"
                  hide-details></v-checkbox>
                <v-checkbox
                  v-model="access_levels.read.activity_max"
                  label="read.activity_max"
                  hide-details></v-checkbox>
                <v-checkbox
                  v-model="access_levels.read.activity_mean"
                  label="read.activity_mean"
                  hide-details></v-checkbox>
                <v-checkbox
                  v-model="access_levels.read.activity_sum"
                  label="read.activity_sum"
                  hide-details></v-checkbox>
                <v-checkbox
                  v-model="access_levels.read.activiry_first"
                  label="read.activiry_first"
                  hide-details></v-checkbox>
                <v-checkbox
                  v-model="access_levels.read.activity_last"
                  label="read.activity_last"
                  hide-details></v-checkbox>
              </v-card-text>
              <v-btn
                fab
                dark
                x-small
                absolute
                right
                bottom
                color="error"
                @click="removeAccessLevel(indexAL)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </v-card>
          </v-col>
          <v-col cols="1">
            <v-btn
              icon
              color="success"
              @click="addAccessLevel()"
              ><v-icon> mdi-plus</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-subheader>CAN</v-subheader>
      <v-row>
        <v-col>
          <v-card>
            <v-subheader>READ</v-subheader>
            <v-container>
              <v-text-field
                label="CAN ID (0x00000000)"
                v-model="data.can_read.can_id"
                dense></v-text-field>
              <v-checkbox
                dense
                v-model="data.can_read.extended_id"
                label="Extended ID"></v-checkbox>
              <v-text-field
                label="ID Mask (0x00000000)"
                v-model="data.can_read.id_mask"
                dense></v-text-field>
              <v-text-field
                label="Start Bit"
                v-model="data.can_read.start_bit"
                type="number"
                dense></v-text-field>
              <v-text-field
                label="Length"
                v-model="data.can_read.len"
                type="number"
                dense></v-text-field>
              <v-select
                v-model="data.can_read.byte_order"
                dense
                :items="byteOrderItems"
                label="Byte Order"></v-select>
              <v-text-field
                label="Offset"
                v-model="data.can_read.offset"
                type="number"
                dense></v-text-field>
              <v-text-field
                label="Factor"
                v-model="data.can_read.factor"
                type="number"
                dense></v-text-field>
              <v-text-field
                label="Min Value"
                v-model="data.can_read.min_value"
                type="number"
                dense></v-text-field>
              <v-text-field
                label="Max Value"
                v-model="data.can_read.max_value"
                type="number"
                dense></v-text-field>
              <v-select
                v-model="data.can_read.type"
                dense
                :items="typeItems"
                label="type"></v-select>
            </v-container>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-subheader>WRITE</v-subheader>
            <v-container>
              <v-text-field
                label="ID (0x00000000)"
                v-model="data.can_write.id"
                dense></v-text-field>
              <v-checkbox
                dense
                v-model="data.can_write.extended"
                label="Extended ID"></v-checkbox>
              <v-text-field
                label="Start Bit"
                v-model="data.can_write.start_bit"
                type="number"
                dense></v-text-field>
              <v-text-field
                label="Length"
                v-model="data.can_write.len"
                type="number"
                dense></v-text-field>
              <v-select
                v-model="data.can_write.byte_order"
                dense
                :items="byteOrderItems"
                label="Byte Order"></v-select>
              <v-text-field
                label="Offset"
                v-model="data.can_write.offset"
                type="number"
                dense></v-text-field>
              <v-text-field
                label="Factor"
                v-model="data.can_write.factor"
                type="number"
                dense></v-text-field>
              <v-text-field
                label="Min Value"
                v-model="data.can_write.min_value"
                type="number"
                dense></v-text-field>
              <v-text-field
                label="Max Value"
                v-model="data.can_write.max_value"
                type="number"
                dense></v-text-field>
              <v-select
                v-model="data.can_write.type"
                dense
                :items="typeItems"
                label="type"></v-select>
              <v-text-field
                label="Default Frame (0x00000000)"
                v-model="data.can_write.default_frame"
                dense></v-text-field>
            </v-container>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-subheader>REQUEST</v-subheader>
            <v-container>
              <v-text-field
                label="ID (0x00000000)"
                v-model="data.can_request.id"
                dense></v-text-field>
              <v-checkbox
                dense
                v-model="data.can_request.extended"
                label="Extended ID"></v-checkbox>
              <v-text-field
                label="Start Bit"
                v-model="data.can_request.start_bit"
                type="number"
                dense></v-text-field>
              <v-text-field
                label="Length"
                v-model="data.can_request.len"
                type="number"
                dense></v-text-field>
              <v-select
                v-model="data.can_request.byte_order"
                dense
                :items="byteOrderItems"
                label="Byte Order"></v-select>
              <v-text-field
                label="Offset"
                v-model="data.can_request.offset"
                type="number"
                dense></v-text-field>
              <v-text-field
                label="Factor"
                v-model="data.can_request.factor"
                type="number"
                dense></v-text-field>
              <v-text-field
                label="Min Value"
                v-model="data.can_request.min_value"
                type="number"
                dense></v-text-field>
              <v-text-field
                label="Max Value"
                v-model="data.can_request.max_value"
                type="number"
                dense></v-text-field>
              <v-select
                v-model="data.can_request.type"
                dense
                :items="typeItems"
                label="type"></v-select>
              <v-text-field
                label="Default Frame (0x00000000)"
                v-model="data.can_request.default_frame"
                dense></v-text-field>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        small
        color="error"
        @click="cancel"
        >CANCEL</v-btn
      >
      <v-btn
        small
        color="success"
        @click="addData"
        >ADD</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    tpsName: {
      name: '',
      languages: '',
    },
    tpsUnit: {
      name: '',
      languages: '',
    },
    data: {
      name: [],
      unit: [],
      access_levels: [],
      can_read: {
        can_id: '',
        extended_id: false,
        id_mask: '',
        start_bit: '',
        len: '',
        byte_order: '',
        offset: '',
        factor: '',
        min_value: '',
        max_value: '',
        type: '',
      },
      can_write: {
        id: '',
        extended: false,
        start_bit: '',
        len: '',
        byte_order: '',
        offset: '',
        factor: '',
        min_value: '',
        max_value: '',
        type: '',
        default_frame: '',
      },
      can_request: {
        id: '',
        extended: false,
        start_bit: '',
        len: '',
        byte_order: '',
        offset: '',
        factor: '',
        min_value: '',
        max_value: '',
        type: '',
        default_frame: '',
      },
    },
    byteOrderItems: ['Intel', 'Motorola'],
    typeItems: ['unsigned', 'signed'],
  }),
  methods: {
    cancel() {
      this.$emit('cancel');
      this.data = {
        name: [],
        unit: [],
        access_levels: [],
        can_read: {
          can_id: '',
          extended_id: false,
          id_mask: '',
          start_bit: '',
          len: '',
          byte_order: '',
          offset: '',
          factor: '',
          min_value: '',
          max_value: '',
          type: '',
        },
        can_write: {
          id: '',
          extended: false,
          start_bit: '',
          len: '',
          byte_order: '',
          offset: '',
          factor: '',
          min_value: '',
          max_value: '',
          type: '',
          default_frame: '',
        },
        can_request: {
          id: '',
          extended: false,
          start_bit: '',
          len: '',
          byte_order: '',
          offset: '',
          factor: '',
          min_value: '',
          max_value: '',
          type: '',
          default_frame: '',
        },
      };
      this.tpsName = {
        name: '',
        languages: '',
      };
      this.tpsUnit = {
        name: '',
        languages: '',
      };
    },
    addDatasName() {
      if (this.tpsName.name && this.tpsName.languages) {
        this.data.name.push({
          text: this.tpsName.name,
          languages: this.tpsName.languages.split(','),
        });
        this.tpsName.name = '';
        this.tpsName.languages = '';
      }
    },
    removeName(indexName) {
      this.data.name.splice(indexName, 1);
    },
    addDatasUnit() {
      if (this.tpsUnit.name && this.tpsUnit.languages) {
        this.data.unit.push({
          text: this.tpsUnit.name,
          languages: this.tpsUnit.languages.split(','),
        });
        this.tpsUnit.name = '';
        this.tpsUnit.languages = '';
      }
    },
    removeUnit(indexUnit) {
      this.data.unit.splice(indexUnit, 1);
    },
    addAccessLevel() {
      this.data.access_levels.push({
        level: '',
        write: false,
        request: false,
        read: {
          array: false,
          temporal_plot: false,
          map_sum: false,
          map_mean: false,
          activity_count: false,
          'activity_last-first': false,
          'activity_max-min': false,
          activity_max: false,
          activity_mean: false,
          activity_sum: false,
          activiry_first: false,
          activity_last: false,
        },
      });
    },
    removeAccessLevel(indexAL) {
      this.data.access_levels.splice(indexAL, 1);
    },
    addData() {
      this.$emit('addData', { ...this.data });
      this.cancel();
    },
  },
};
</script>
