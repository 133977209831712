<template>
  <v-card elevation="4">
    <v-card-title> Canbus Values Config </v-card-title>

    <v-card-text>
      <v-select
        :items="canbusvaluesconfigs"
        label="Name"
        item-text="configuration_name"
        return-object
        v-model="select"></v-select>
    </v-card-text>

    <counterCanbusValuesConfigsList
      v-if="select"
      :configs="configs" />
    <v-card-text v-if="select">
      {{ select.createdAt | moment('lll') }} - {{ select.creator.firstname }}
      {{ select.creator.lastname }}
    </v-card-text>
    <v-card-actions
      v-if="select"
      class="justify-center">
      <v-btn
        small
        color="secondary"
        @click="dialogSetCounters = true"
        >SET TO COUNTERS</v-btn
      >
    </v-card-actions>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <add-canbus-values-config-dialog @update="update" />
    </v-card-actions>
    <v-dialog
      v-model="dialogSetCounters"
      persistent
      width="600">
      <set-canbus-values-config-to-counters
        @close="dialogSetCounters = false"
        @setCanbusConfig="setCanbusConfig" />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import AddCanbusValuesConfigDialog from './AddCanbusValuesConfigDialog.vue';
import SetCanbusValuesConfigToCounters from './SetCanbusValuesConfigToCounters.vue';
import counterCanbusValuesConfigsList from '../counters/counterCanbusValuesConfigsList.vue';

export default {
  props: ['canbusvaluesconfigs'],
  components: {
    AddCanbusValuesConfigDialog,
    SetCanbusValuesConfigToCounters,
    counterCanbusValuesConfigsList,
  },
  data() {
    return {
      select: '',
      dialogSetCounters: false,
    };
  },
  computed: {
    configs() {
      const locale = moment.locale();

      return this.select.datas.map((elem) => {
        let name = elem.name.find((tps) => tps.languages.includes(locale));
        if (name === undefined) {
          name = elem.name.find((tps) => tps.languages.includes('default'));
        }
        if (name === undefined) {
          name = elem.name.find((tps) => tps.languages);
        }
        return {
          nameTable: name?.text,
          ...elem,
          id: uuidv4(),
        };
      });
    },
  },
  methods: {
    ...mapActions(['setCanbusValuesConfigs']),
    update(val) {
      this.select = val;
      console.log(val);
    },
    setCanbusConfig(val) {
      this.dialogSetCounters = false;
      this.setCanbusValuesConfigs({
        idCanbusValuesConfig: this.select.id,
        counters: val,
      });
    },
  },
};
</script>
