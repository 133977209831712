<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" v-for="(can, index) in cans" :key="index">
        <v-card>
          <v-card-title class="text-subtitle-1 font-weight-bold">
            Can {{ index }}
          </v-card-title>
          <v-card-text>
            <span><b>Can Mode:</b> {{ can.mode }}</span
            ><br />
            <span><b>Speed:</b> {{ can.speed }}</span
            ><br />
            <span><b>Max mask:</b> {{ can.max_mask }}</span
            ><br />
            <span><b>Max Filters:</b> {{ can.max_filters }}</span>
          </v-card-text>
          <v-list dense expand>
            <v-subheader>Details:</v-subheader>
            <v-list-group no-action>
              <template v-slot:activator>
                <v-list-item-title
                  >Masks ({{ can.masks.length }})</v-list-item-title
                >
              </template>
              <v-list-group
                no-action
                sub-group
                v-for="(mask, index) in can.masks"
                :key="index"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Masks[{{ index }}]</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item dense>
                  <v-list-item-content>
                    <span><b>sid:</b> {{ mask.sid }}</span
                    ><br />
                    <span><b>eid:</b> {{ mask.eid }}</span
                    ><br />
                    <span><b>mide:</b> {{ mask.mide }}</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list-group>

            <v-list-group no-action>
              <template v-slot:activator>
                <v-list-item-title
                  >Filters ({{ can.filters.length }})</v-list-item-title
                >
              </template>
              <v-list-group
                no-action
                sub-group
                v-for="(filter, index) in can.filters"
                :key="index"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Filters[{{ index }}]</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item dense>
                  <v-list-item-content>
                    <span><b>sid:</b> {{ filter.sid }}</span
                    ><br />
                    <span><b>eid:</b> {{ filter.eid }}</span
                    ><br />
                    <span><b>exid:</b> {{ filter.exid }}</span
                    ><br />
                    <span><b>enable:</b> {{ filter.enable }}</span
                    ><br />
                    <span><b>mask:</b> {{ filter.mask }}</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ['cans'],
};
</script>
