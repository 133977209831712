<template>
  <v-data-table
    :expanded.sync="expanded"
    :headers="headers"
    :items="configs"
    show-expand
    dense
    item-key="id"
  >
    <template v-slot:expanded-item="{ item }">
      <td :colspan="headers.length">
        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="12">
              <a-counter-canbus-values-configs :config="item" />
            </v-col>
          </v-row>
        </v-container>
      </td>
    </template>
  </v-data-table>
</template>

<script>

import aCounterCanbusValuesConfigs from './aCounterCanbusValuesConfigs.vue';

export default {
  props: ['configs'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    aCounterCanbusValuesConfigs,
  },
  data: () => ({
    expanded: [],
    headers: [
      { text: 'Name', value: 'nameTable' },
      { text: '', value: 'data-table-expand' },
    ],
  }),
  computed: {

  },
};
</script>

<style scoped>
.configList {
  height: 450px;
  overflow: auto;
  background-color: #eceff1;
}

.fullwidth {
  width: 100%;
}
</style>
