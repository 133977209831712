<template>
  <v-card>
    <v-card-title> Create Canbus Config </v-card-title>

    <v-card-text>
      <v-btn
        small
        color="primary"
        @click="loadFromJson">
        LOAD FROM JSON
        <v-icon
          right
          dark
          small>
          mdi-cloud-upload
        </v-icon>
      </v-btn>
      <v-dialog
        v-model="dialogLoadFromJson"
        persistent>
        <load-canbus-from-json
          @load="loadjson"
          @cancel="
            json = ''
            dialogLoadFromJson = false
          "
          :jsonvalue="json" />
      </v-dialog>
    </v-card-text>
    <v-card-text>
      <v-select
        :items="adminGroups"
        label="Admin Group"
        item-text="name"
        item-value="id"
        prepend-icon="mdi-account-group"
        v-model="selectAdminGroup"></v-select
    ></v-card-text>
    <v-card-text>
      <v-text-field
        v-model="config.name"
        label="Name"
        prepend-icon="mdi-comment-edit-outline"></v-text-field>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          v-for="(can, index) in config.cans"
          :key="index">
          <v-card>
            <v-card-title class="text-subtitle-1 font-weight-bold"> Can {{ index }} </v-card-title>
            <v-card-text>
              <v-select
                dense
                :items="canMode"
                label="Can Mode"
                v-model="can.mode"></v-select>
              <v-select
                dense
                :items="speed"
                label="Speed"
                v-model="can.speed"></v-select>

              <span><b>Max mask:</b> {{ can.max_mask }}</span
              ><br />
              <span><b>Max Filters:</b> {{ can.max_filters }}</span>
            </v-card-text>

            <v-list
              dense
              expand>
              <v-subheader>Details:</v-subheader>
              <v-list-group no-action>
                <template v-slot:activator>
                  <v-list-item-title
                    >Masks ({{ can.masks ? can.masks.length : 0 }})</v-list-item-title
                  >
                  <v-list-item-action>
                    <v-btn
                      @click.stop="addMask(can)"
                      icon>
                      <v-icon color="success">mdi-plus</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </template>
                <v-list-group
                  no-action
                  sub-group
                  v-for="(mask, index) in can.masks"
                  :key="index">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title
                        >Masks[{{ index }}]
                        <v-btn
                          icon
                          small
                          @click.stop="removeMask(can, index)">
                          <v-icon
                            small
                            color="error"
                            >mdi-delete</v-icon
                          >
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              dense
                              label="SID"
                              v-model="mask.sid"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              dense
                              label="EID"
                              v-model="mask.eid"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-checkbox
                              dense
                              label="mide"
                              v-model="mask.mide"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-group>
              <v-list-group no-action>
                <template v-slot:activator>
                  <v-list-item-title
                    >Filters ({{ can.filters ? can.filters.length : 0 }})</v-list-item-title
                  >
                  <v-list-item-action>
                    <v-btn
                      @click.stop="addFilter(can)"
                      icon>
                      <v-icon color="success">mdi-plus</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </template>
                <v-list-group
                  no-action
                  sub-group
                  v-for="(filter, index) in can.filters"
                  :key="index">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title
                        >Filters[{{ index }}]
                        <v-btn
                          icon
                          small
                          @click.stop="removeFilter(can, index)">
                          <v-icon
                            small
                            color="error"
                            >mdi-delete</v-icon
                          >
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-container fluid>
                        <v-row no-gutters>
                          <v-col cols="12">
                            <v-text-field
                              dense
                              label="SID"
                              v-model="filter.sid"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              dense
                              label="EID"
                              v-model="filter.eid"></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6">
                            <v-checkbox
                              dense
                              label="exid"
                              v-model="filter.exid"></v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6">
                            <v-checkbox
                              dense
                              label="enable"
                              v-model="filter.enable"></v-checkbox>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              dense
                              label="Mask"
                              v-model="filter.mask"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-alert
      v-model="alert"
      border="left"
      close-text="Close Alert"
      color="error"
      dark
      dismissible>
      {{ messageALert }}
    </v-alert>
    <v-card-actions>
      <v-spacer />
      <v-btn
        @click="cancel"
        color="error"
        >CANCEL</v-btn
      >
      <v-btn
        color="success"
        @click="saveConfig"
        >SAVE</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LoadCanbusFromJson from './LoadCanbusFromJson.vue'

export default {
  components: {
    LoadCanbusFromJson,
  },
  data() {
    return {
      selectAdminGroup: '',
      alert: false,
      messageALert: '',
      json: '',
      dialogLoadFromJson: false,
      canMode: ['Auto', 'Listen_Only', 'Normal'],
      speed: ['125K', '250K', '500K', '1M'],
      config: {
        name: '',
        cans: [
          {
            mode: 'Auto',
            speed: '',
            max_mask: 4,
            max_filters: 31,
            masks: [],
            filters: [],
          },
          {
            mode: 'Auto',
            speed: '',
            max_mask: 4,
            max_filters: 31,
            masks: [],
            filters: [],
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters(['adminGroups']),
  },
  mounted() {
    this.setAdminGroup()
  },
  methods: {
    ...mapActions(['createCanbusConfig']),
    setAdminGroup() {
      this.selectAdminGroup = this.adminGroups[0]?.id
    },
    reset() {
      this.config.name = ''
      this.config.cans = [
        {
          mode: 'Auto',
          speed: '250K',
          max_mask: 4,
          max_filters: 31,
          masks: [],
          filters: [],
        },
        {
          mode: 'Auto',
          speed: '250K',
          max_mask: 4,
          max_filters: 31,
          masks: [],
          filters: [],
        },
      ]
      this.selectAdminGroup = ''
    },
    cancel() {
      this.reset()
      this.$emit('close')
    },
    removeMask(can, index) {
      can.masks.splice(index, 1)
    },
    addMask(can) {
      if (can.masks.length < 4) {
        can.masks.push({
          sid: 0,
          eid: 0,
          mide: false,
        })
      }
    },
    removeFilter(can, index) {
      can.filters.splice(index, 1)
    },
    addFilter(can) {
      if (can.filters.length < 31) {
        can.filters.push({
          sid: 0,
          eid: 0,
          exid: false,
          enable: false,
          mask: 0,
        })
      }
    },
    loadFromJson() {
      this.dialogLoadFromJson = true
      this.json = String(JSON.stringify(this.config, null, 4))
    },
    loadjson(val) {
      this.config.name = val.name
      this.config.cans = val.cans
      this.json = ''
      this.dialogLoadFromJson = false
    },
    async saveConfig() {
      this.messageALert = ''
      this.alert = false

      if (!this.config.name) {
        this.messageALert = 'You must set a name'
        this.alert = true
        return
      }
      if (!this.selectAdminGroup) {
        this.messageALert = 'You must set an admin group'
        this.alert = true
        return
      }
      try {
        const newConf = await this.createCanbusConfig({
          ...this.config,
          adminGroup: this.selectAdminGroup,
        })
        this.reset()
        console.log(newConf)
        this.$emit('update', newConf)
      } catch (error) {
        let messageError = error?.response?.data?.message

        if (error?.response?.data?.data) {
          messageError += `: ${error.response.data.data.map((elem) => elem.msg).join(', ')}`
        }
        this.messageALert = messageError || 'error'
        this.alert = true
      }
    },
  },
}
</script>
