<template>
  <v-autocomplete
    multiple
    item-text="text"
    item-value="value"
    :items="formatedUsers"
    v-model="selectedUsers"
  >
    <template v-slot:selection="data">
      <v-chip small v-bind="data.attrs" close @click:close="remove(data.item)">
        {{ data.item.chip }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    value: Array,
    default: () => [],
  },
  data() {
    return {
      selectedUsers: this.value,
    };
  },
  watch: {
    value(val) {
      this.selectedUsers = val;
    },
    selectedUsers(val) {
      this.$emit('input', val);
    },
  },
  computed: {
    ...mapGetters(['users']),
    formatedUsers() {
      return this.users.map((elem) => ({
        text: `${elem.email} (${elem.firstname} ${elem.lastname})`,
        value: elem.id,
        chip: elem.email,
      }));
    },
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    ...mapActions(['getAllUsers']),
    remove(item) {
      const index = this.selectedUsers.indexOf(item.value);
      if (index >= 0) this.selectedUsers.splice(index, 1);
    },
  },
};
</script>
