<template>
  <v-card>
    <v-card-title> Load From Json </v-card-title>

    <v-card-text>
      <v-textarea
        label="JSON Config"
        v-model="editor"
        outlined
        rows="12"
      ></v-textarea>
    </v-card-text>
        <v-card-text>
      <v-alert
        v-model="alert"
        border="left"
        close-text="Close Alert"
        color="error"
        dark
        dismissible
      >
        {{ error }}
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn small color="error" @click="cancel">CANCEL</v-btn>
      <v-btn small color="success" @click="load">LOAD</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['jsonvalue'],
  data() {
    return {
      editor: this.jsonvalue,
      error: '',
      alert: false,
    };
  },
  watch: {
    jsonvalue() {
      this.editor = this.jsonvalue;
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    load() {
      this.alert = false;
      this.error = '';
      try {
        const tps = JSON.parse(this.editor);
        this.editor = JSON.stringify(tps, null, 4);
        this.$emit('update', tps);
        this.cancel();
      } catch (error) {
        console.log(error);
        this.error = 'Bad JSON Format';
        this.alert = true;
      }
    },
  },
};
</script>
