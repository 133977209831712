<template>
  <v-card>
    <v-card-title> ADD THEME </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="name"
        label="Name"
        prepend-icon="mdi-comment-edit-outline"
      ></v-text-field>
      <v-file-input
        accept="image/png, image/jpeg, image/bmp"
        prepend-icon="mdi-camera"
        label="Logo"
        v-model="imageFile"
        @change="loadImage"
      ></v-file-input>
      <v-toolbar :color="colors.primary" dense class="mb-3">
        <img v-if="image" :src="image" class="mr-3" height="25" />
      </v-toolbar>

      <v-select
        :items="adminGroups"
        label="Admin Group"
        item-text="name"
        item-value="id"
        prepend-icon="mdi-account-group"
        v-model="selectAdminGroup"
      ></v-select>
    </v-card-text>
    <v-card-text>
      <span>Colors</span>
      <v-list>
        <v-list-item v-for="key in colorsName" :key="key">
          <v-list-item-content>
            <span :color="colors[key]">{{ key }}</span>
          </v-list-item-content>
          <v-list-item-action>
            <input type="color" id="head" name="head" v-model="colors[key]" />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn small color="error" @click="cancel">CANCEL</v-btn>
      <v-btn small color="success" :disabled="isCompleted" @click="uploadTheme"
        >UPLOAD</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      name: '',
      colors: {
        primary: '#78be20',
        secondary: '#41473A',
        accent: '#385625',
        error: '#FF5252',
        info: '#2196F3',
        success: '#658d1b',
        warning: '#FFC107',
      },
      selectAdminGroup: '',
      imageFile: null,
      image: '',
    };
  },
  computed: {
    ...mapGetters(['adminGroups']),
    colorsName() {
      return Object.keys(this.colors);
    },
    isCompleted() {
      return !(this.image && this.selectAdminGroup && this.name);
    },
  },
  mounted() {
    this.setAdminGroup();
  },
  methods: {
    loadImage(file) {
      if (file) {
        const fR = new FileReader();
        fR.addEventListener('load', (e) => {
          this.image = e.target.result;
        });
        fR.readAsDataURL(file);
      } else {
        this.image = file;
      }
    },
    setAdminGroup() {
      this.selectAdminGroup = this.adminGroups[0]?.id;
    },
    resetForm() {
      this.name = '';
      this.selectAdminGroup = this.adminGroups[0]?.id;
      this.colors = {
        primary: '#78be20',
        secondary: '#41473A',
        accent: '#385625',
        error: '#FF5252',
        info: '#2196F3',
        success: '#658d1b',
        warning: '#FFC107',
      };
      this.image = '';
      this.imageFile = null;
    },
    cancel() {
      this.$emit('close');
      this.resetForm();
    },
    uploadTheme() {
      const payload = {
        name: this.name,
        adminGroup: this.selectAdminGroup,
        logo: this.image,
        colors: { ...this.colors },
      };
      this.$emit('upload', payload);
      this.resetForm();
    },
  },
};
</script>
