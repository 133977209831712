<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small color="success" dark v-bind="attrs" v-on="on"> ADD </v-btn>
    </template>
    <add-canbus-values-config @close="dialog = false"  @addCanbusValuesConfig='update'/>
  </v-dialog>
</template>

<script>
import AddCanbusValuesConfig from './AddCanbusValuesConfig.vue';

export default {
  data() {
    return {
      dialog: false,
    };
  },
  components: {
    AddCanbusValuesConfig,
  },
  methods: {
    update(val) {
      this.$emit('update', val);
    },
  },
};
</script>
