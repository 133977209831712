<template>
  <v-card>
    <v-card-title>Set Canbus Config to Counters</v-card-title>
    <v-card-text>
      <counters-selector-autocomplete v-model="selectedCounters" />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn small color="error" @click="cancel">CANCEL</v-btn>
      <v-btn small color="success" @click="setCanbusConfig" >OK</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import countersSelectorAutocomplete from '../selectors/countersSelectorAutocomplete.vue';

export default {
  data() {
    return {
      selectedCounters: [],
    };
  },
  components: {
    countersSelectorAutocomplete,
  },
  methods: {
    reset() {
      this.selectedCounters = [];
    },
    setCanbusConfig() {
      this.$emit('setCanbusConfig', [...this.selectedCounters]);
      this.reset();
    },
    cancel() {
      this.$emit('close');
      this.reset();
    },
  },
};
</script>
