<template>
  <v-card elevation="4">
    <v-card-title> Admin Accounts </v-card-title>
    <v-card-text>
      <v-select
        :items="accounts"
        label="Name"
        item-text="name"
        return-object
        v-model="select"
      ></v-select>
    </v-card-text>
    <v-tabs v-if="select">
      <v-tab>Users</v-tab>
      <v-tab>Counters</v-tab>
      <v-tab-item>
        <v-spacer></v-spacer>

        <v-data-table
          :headers="headers"
          :items="select.users"
          :items-per-page="5"
        >
          <template v-slot:top>
            <v-toolbar flat dense>
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="addUsersDialog = true">add Users</v-btn>
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          :headers="headersCounter"
          :items="select.counters"
          :items-per-page="5"
          class="elevation-1"
        ></v-data-table>
      </v-tab-item>
    </v-tabs>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer />
      <v-btn small color="success" @click="dialogAddAccount = true"
        >ADD ADMIN ACCOUNT</v-btn
      >
    </v-card-actions>
    <v-dialog v-model="dialogAddAccount" persistent width="500">
      <add-admin-account @close="dialogAddAccount = false" />
    </v-dialog>
    <v-dialog v-model="addUsersDialog">
      <add-email-admin-account :idAccount="select.id" @close="addUsersDialog = false"/>
    </v-dialog>
  </v-card>
</template>

<script>
import addAdminAccount from './AddAdminAccount.vue';
import addEmailAdminAccount from './AddEmailAdminAccount.vue';

export default {
  props: ['accounts'],
  components: {
    addAdminAccount,
    addEmailAdminAccount,
  },
  data() {
    return {
      addUsersDialog: false,
      select: '',
      dialogAddAccount: false,
      headers: [
        {
          text: 'Email',
          align: 'start',
          sortable: false,
          value: 'email',
        },
        { text: 'Firstname', value: 'firstname' },
        { text: 'Lastname', value: 'lastname' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headersCounter: [
        {
          text: 'Counter',
          align: 'start',
          sortable: false,
          value: 'counter',
        },
        {
          text: 'Access Level',
          align: 'start',
          sortable: false,
          value: 'access_level',
        },
      ],
    };
  },
};
</script>
