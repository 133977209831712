<template>
  <v-card>
    <v-card-title> ADD ADMIN ACCOUNT </v-card-title>
    <v-card-text>
      {{errorMessage}}
    </v-card-text>
    <v-card-text>
      <v-select
        :items="adminGroups"
        label="Admin Group"
        item-text="name"
        item-value="id"
        prepend-icon="mdi-account-group"
        v-model="selectAdminGroup"
      ></v-select>
      <v-text-field
        v-model="name"
        label="New Admin Account Name"
        prepend-icon="mdi-comment-edit-outline"
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn small color="error" @click="cancel">CANCEL</v-btn>
      <v-btn small color="success" :disabled="!isCompleted" :loading="loading" @click="addAccount"
        >ADD</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      name: '',
      selectAdminGroup: '',
      loading: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters(['adminGroups']),
    isCompleted() {
      return this.name !== '';
    },
  },
  methods: {
    ...mapActions(['createAdminAccount']),
    init() {
      this.name = '';
      this.selectAdminGroup = '';
    },
    cancel() {
      this.init();
      this.$emit('close');
    },
    async addAccount() {
      this.loading = true;
      try {
        const tps = await this.createAdminAccount({
          name: this.name,
          adminGroup: this.selectAdminGroup,
        });
        this.init();
        this.$emit('update', tps);
        this.$emit('close');
      } catch (error) {
        this.errorMessage = error.response.data.data.map((val) => val.msg).join(', ');
      }

      this.loading = false;
    },
  },
};
</script>
