<template>
  <v-card elevation="4">
    <v-card-title> Themes </v-card-title>
    <v-card-text>
      <v-select
        :items="themes"
        label="Name"
        item-text="name"
        return-object
        v-model="select"
      ></v-select>
    </v-card-text>

    <v-card-text v-if="select">
      <v-toolbar :color="select.colors.primary" dense class="mb-3">
        <img v-if="select.logo" :src="select.logo" class="mr-3" height="25" />
      </v-toolbar>
      <span>Colors</span>
      <v-list>
        <v-list-item v-for="key in Object.keys(select.colors)" :key="key">
          <v-list-item-content>
            <span :color="select.colors[key]">{{ key }}</span>
          </v-list-item-content>
          <v-list-item-action>
            <input
              @change="putTheme"
              type="color"
              id="head"
              name="head"
              v-model="select.colors[key]"
            />
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <div class="mt-2">
        <span>Admin Group: {{ select.adminGroup.name }}</span>
      </div>
    </v-card-text>
    <v-card-actions v-if="select" class="justify-center">
      <v-btn small color="secondary" @click="dialogSetUsers = true"
        >SET TO USERS</v-btn
      >
    </v-card-actions>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer />
      <v-btn small color="success" @click="dialogAddTheme = true">ADD</v-btn>
    </v-card-actions>
    <v-dialog v-model="dialogAddTheme" persistent width="500">
      <add-theme @close="dialogAddTheme = false" @upload="addTheme" />
    </v-dialog>
    <v-dialog v-model="dialogSetUsers" persistent width="600">
      <set-theme @close="dialogSetUsers = false" @setTheme="setTheme"/>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import addTheme from './AddTheme.vue';
import setTheme from './SetTheme.vue';

export default {
  components: {
    addTheme,
    setTheme,
  },
  data() {
    return {
      dialogAddTheme: false,
      dialogSetUsers: false,
      select: '',
    };
  },
  props: ['themes'],
  methods: {
    ...mapActions(['createTheme', 'updateTheme', 'setThemeToUsers']),
    async addTheme(theme) {
      this.dialogAddTheme = false;
      this.select = await this.createTheme(theme);
    },
    putTheme() {
      this.updateTheme({
        id: this.select.id,
        theme: {
          name: this.select.name,
          adminGroup: this.select.adminGroup.id,
          colors: this.select.colors,
        },
      });
    },
    setTheme(users) {
      this.dialogSetUsers = false;
      this.setThemeToUsers({
        users,
        idTheme: this.select.id,
      });
    },
  },
};
</script>
