<template>
  <v-card>
    <v-card-title> Load From Json </v-card-title>

    <v-card-text>
      <v-textarea
        label="JSON Config"
        v-model="editor"
        outlined
        rows="12"
      ></v-textarea>
    </v-card-text>
        <v-card-text>
      <v-alert
        v-model="alert"
        border="left"
        close-text="Close Alert"
        color="error"
        dark
        dismissible
      >
        {{ error }}
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn small color="error" @click="cancel">CANCEL</v-btn>
      <v-btn small color="success" @click="load">LOAD</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Validator } from 'jsonschema';

export default {
  props: ['jsonvalue'],
  data() {
    return {
      editor: this.jsonvalue,
      error: '',
      alert: false,
    };
  },
  watch: {
    jsonvalue() {
      console.log('change');
      this.editor = this.jsonvalue;
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    load() {
      const v = new Validator();

      const maskSchema = {
        id: '/masks',
        type: 'object',
        properties: {
          sid: { type: 'number' },
          eid: { type: 'number' },
          mide: { type: 'boolean' },
        },
        required: ['sid', 'eid', 'mide'],
      };

      v.addSchema(maskSchema, '/masks');

      const filterSchema = {
        id: '/filters',
        type: 'object',
        properties: {
          sid: { type: 'number' },
          eid: { type: 'number' },
          exid: { type: 'boolean' },
          enable: { type: 'boolean' },
          mask: { type: 'number' },
        },
        required: ['sid', 'eid', 'exid', 'enable', 'mask'],
      };

      v.addSchema(filterSchema, '/filters');

      const cansSchema = {
        id: '/cans',
        type: 'object',
        properties: {
          mode: { type: 'string' },
          speed: { type: 'string' },
          max_mask: { type: 'number' },
          max_filters: { type: 'number' },
          masks: {
            type: 'array',
            items: { $ref: '/masks' },
          },
          filters: {
            type: 'array',
            items: { $ref: '/filters' },
          },
        },
      };

      v.addSchema(cansSchema, '/cans');

      const schema = {
        id: '/Config',
        type: 'object',
        properties: {
          name: { type: 'string', value: 'test' },
          cans: {
            type: 'array',
            items: { $ref: '/cans' },
          },
        },
      };

      this.alert = false;
      this.error = '';
      try {
        const tps = JSON.parse(this.editor);
        this.editor = JSON.stringify(tps, null, 4);
        const test = v.validate(tps, schema);

        if (!test.valid) {
          this.error = test.errors
            .map((item) => `${item.property} ${item.message}`).join(', ');
          this.alert = true;
        } else {
          const response = {
            name: tps.name,
            cans: tps.cans.map((can) => ({
              mode: can.mode,
              speed: can.speed,
              max_mask: can.max_mask,
              max_filters: can.max_filters,
              masks: can.masks.map((mask) => ({
                sid: mask.sid,
                eid: mask.eid,
                mide: mask.mide,
              })),
              filters: can.filters.map((filter) => ({
                sid: filter.sid,
                eid: filter.eid,
                exid: filter.exid,
                enable: filter.enable,
                mask: filter.mask,
              })),
            })),
          };
          this.$emit('load', response);
        }
      } catch (error) {
        console.log(error);
        this.error = 'Bad JSON Format';
        this.alert = true;
      }
    },
  },
};
</script>
